/* Button added on top for Free Confidential Webinar This Friday at 1pm Click here to learn more */
@font-face {
  font-family: "Soleil_Book";
  src: url("./fonts/SoleilBook.otf");
  font-display: swap;
}
@font-face {
  font-family: "Soleil_Bold";
  font-weight: bold;
  src: url("./fonts/SoleilBold.otf");
  font-display: swap;
}
body {
  color: #333333;
  font-family: 'Poppins';
  font-weight: 400;
  background-size: cover;
  background-position: 100%;
  height: 1005 !important;
}
.App {
  text-align: center;
}
.header-buttons-col {
  display: flex;
  justify-content: flex-end;
}
.header-buttons-col .button-div a {
  color: white;
}
.nav-bar-a:hover {
  text-decoration: none;
}
@media (min-width: 1200px) {
  .nav-item {
    max-width: fit-content !important;
  }
}
.header-buttons-col .button-div {
  background-color: #00aae4;
  color: white;
  text-align: center;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  border-radius: 15px;
  padding: 6px 20px !important;
  line-height: 1.5;
  text-decoration: none;
}
.button-div-col {
  align-self: center;
  padding: 0 0.5rem 0 0;
}
.header-buttons-col .button-div {
  align-items: center;
  flex-wrap: nowrap;
}
.button-div-col a:hover {
  text-decoration: none;
}
.header-buttons-col button :focus {
  background-color: white;
  color: #0481b5;
}
#root {
  background-color: white;
}
.phoneIcon {
  width: 12px;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545 !important;
}
@media only screen and (max-width: 1299px) {
  .header-buttons-col .button-div {
    font-size: 12px;
    padding: 0.2rem 0.5rem;
  }
}
@media only screen and (min-width: 1500px) {
  .header-buttons-col .button-div {
    background-size: 100% 100%;
  }
}
@media only screen and (max-width: 1199.67px) {
  .nav-item {
    max-width: fit-content !important;
  }
}
@media only screen and (max-width: 1099px) {
  .header-buttons-col .button-div {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991.97px) {
  .header-buttons-col {
    justify-content: center;
    padding-bottom: 1rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .header-buttons-col .button-div {
    background-size: 100% 100%;
  }
  .header-buttons-col .button-div {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767.9px) {
  .header-buttons-col .button-div {
    display: flex;
    height: 45px;
    background-size: 100% 100%;
    font-size: 14px;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 575.67px) {
  .header-buttons-col .button-div {
    font-size: 12px;
    background-size: cover;
  }
}
.copyright {
  font-size: 18px;
}
.product-table {
  position: relative;
}
@media (min-width: 1201px) {
  .containerbackground {
    margin: 5rem;
    position: absolute;
    top: unset;
    bottom: 90px;
    right: 25px;
    left: unset;
    bottom: 6rem;
    font-size: 37px;
    transform: rotate(-27.03deg);
    -webkit-transform: rotate(-27.03deg);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    z-index: 100 !important;
    color: rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 771px) and (max-width: 1200px) {
  .containerbackground {
    margin: 0rem;
    position: absolute;
    top: unset;
    right: 0px;
    left: -47px;
    bottom: 27%;
    font-size: 45px;
    transform: rotate(-27.03deg);
    -webkit-transform: rotate(-27.03deg);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    z-index: 100 !important;
  }
  .containerbackground br {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .containerbackground {
    left: -2px;
    bottom: 35%;
    font-size: 35px;
    transform: rotate(-27.03deg);
    -webkit-transform: rotate(-27.03deg);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    z-index: 100 !important;
    color: rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 601px) and (max-width: 770px) {
  .containerbackground {
    margin: 0rem;
    position: absolute;
    top: unset;
    left: -40px;
    right: 0px;
    bottom: 25%;
    font-size: 32px;
    transform: rotate(-27.03deg);
    -webkit-transform: rotate(-27.03deg);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.15);
    z-index: 100 !important;
  }
  .partner_benefits_title_part1_pricing_table {
    font-size: 30px;
  }
}
@media (min-width: 540px) and (max-width: 600px) {
  .containerbackground {
    margin: 0rem;
    position: absolute;
    right: 0px;
    left: -46px;
    top: unset;
    bottom: 21%;
    font-size: 34px;
    transform: rotate(-27.03deg);
    -webkit-transform: rotate(-27.03deg);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.15);
    z-index: 100 !important;
  }
}
@media (min-width: 401px) and (max-width: 539px) {
  .containerbackground {
    margin: 0rem;
    position: absolute;
    right: 0px;
    left: -7px;
    top: unset;
    bottom: 35%;
    font-size: 30px;
    transform: rotate(-27.03deg);
    -webkit-transform: rotate(-27.03deg);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    z-index: 100 !important;
    color: rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 281px) and (max-width: 400px) {
  .containerbackground {
    margin: 0rem;
    position: absolute;
    right: 0px;
    left: 0px;
    top: unset;
    bottom: 35%;
    font-size: 21px;
    transform: rotate(-27.03deg);
    -webkit-transform: rotate(-27.03deg);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    z-index: 100 !important;
    color: rgba(0, 0, 0, 0.15);
  }
  .containerbackground br {
    display: block;
  }
}
@media only screen and (max-width: 767.91px) {
  .contact-section .contact-form {
    padding: 2rem;
    margin: 2rem 0rem;
    border: none;
  }
  .header-buttons-col .button-div {
    background-size: 100% 100%;
  }
  .contact-form .clrd-btn {
    border-radius: 12px;
    font-size: 36px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 506px) {
  .header-buttons-col .button-div {
    font-size: 10px;
    background-size: contain;
    background-position: center;
    height: 35px;
  }
  .contact-section .contact-form {
    padding: 1.25rem;
    margin: 2rem 0rem;
  }
  .contact-section h4 {
    font-size: 36px;
  }
  .contact-section .contact-form .tcpa {
    font-size: 12px;
    line-height: 15px;
  }
  .contact-form .clrd-btn {
    font-size: 24px;
    padding: 0.25rem 3.5rem;
  }
}
@media only screen and (max-width: 399.91px) {
  .contact-section h4 {
    font-size: 32px;
  }
  .contact-form .clrd-btn {
    font-size: 20px;
    padding: 0.25rem 2.5rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 545px) {
  .header-buttons-col {
    padding-bottom: 0.5rem !important;
  }
}
