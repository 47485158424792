@media only screen and (min-width: 992px) {
  .mobile-AccessibilityMenu-show {
    display: none;
  }
  #responsive-navbar-nav{
    margin-right: 2rem;
  }
}
@media only screen and (max-width: 991px) {
  .desktop-AccessibilityMenu-show {
    display: none;
  }
  .mobile-AccessibilityMenu-show {
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 2;
  }
}